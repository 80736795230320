<template>
  <div>
    <NavBar></NavBar>
    <b-container>

      <h4 class="mt-2">Your order was successful! Hooray!</h4>
      <hr>
      <p>Many thanks for your support :)</p>

      <p>I sent you an email with the order details (with download links)</p>

      <p>Do not hesitate to contact me in case of questions or problems.</p>

      <p>You can reply to the email or <b-link @click="openCrisp">use the chatbox.</b-link></p>

      Maximilien.
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import AuthHelper from "@/authHelper";
import Webservice from "@/webservice";
export default {
name: "Success",
  components: {Footer, NavBar},
  created() {
    window.scrollTo(0, 0);
    AuthHelper.getOrCreateAnonUser().then((user)=> {
      Webservice.tracking({
        action: {
          referrer: document.referrer,
          lang: navigator.language || navigator.userLanguage,
          name: "VISIT_SUCCESS_PAGE",
          utm: {
            source: this.$route.query.utm_source,
            medium: this.$route.query.utm_medium,
            campaign: this.$route.query.utm_campaign,
            term: this.$route.query.utm_term,
            content: this.$route.query.utm_content,
          }
        }
      }, user.idToken)
    })
  },
  methods: {
    openCrisp() {
      window.$crisp.push(['do', 'chat:open'])
    }
  },

}
</script>

<style scoped>

</style>